<template>
    <div class="detail">
        <creation-detail :info="info" @refreshBtn="getDetails"></creation-detail>
    </div>
</template>
<script>
import CreationDetail from '../components/creationDetail/audio.vue'
export default {
    components: {
        CreationDetail
    },
    data() {
        return {
            title: '音频详情',
            id: 0,
            info: {},
        }
    },
    created() {
        this.id = this.$route.params.id
        if (this.id) {
            this.getDetails()
        }
        // 详情页导航
        var name = this.$route.query.name || `音频${this.$route.params.id}`
        var key = this.$route.meta.key + '_' + this.$route.params.id
        this.$store.dispatch('tabsArr/getName', { name, key })
    },
    mounted() {

    },
    methods: {
        getDetails() {
            this.$creationApi.getAudioDetails(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = res.result

                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.detail {
    width: 100%;
    height: 100%;
}
</style>