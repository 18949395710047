<template>
    <div class="detail">
        <div class="con-top">
            <div class="con-top-l section-wrap p20">
                <div class="title">作品信息</div>
                <div class="info-item">
                    <div class="label">作品名称：</div>
                    <div class="value">
                        <edit-name :name="info.name" :id="info.id" @editName="nameChange"></edit-name>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">模型名称：</div>
                    <div class="value">
                        <div class="txt">{{ info.tts_list_name }}</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品时长：</div>
                    <div class="value">
                        <div class="txt">{{ info.duration }}秒</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">作品状态：</div>
                    <div class="value">
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 1">创作中</el-tag>
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 2">排队中</el-tag>
                        <el-tag class="status" size="small" type="warning" v-if="info.status == 3">合成中</el-tag>
                        <el-tag class="status" size="small" type="success" v-if="info.status == 4">创作成功</el-tag>
                        <el-tag class="status" size="small" type="danger" v-if="info.status == 5">创作失败</el-tag>
                    </div>
                </div>
                <div class="info-item" v-if="info.status == 4">
                    <div class="label">作品链接：</div>
                    <div class="value">
                        <div class="link_url">
                            <div class="txt">{{ info.production_file }}</div>
                            <div class="copy-btn cursor" @click="copyUrl(info.production_file)">复制</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="con-top-r section-wrap p20">
                <div class="audio_container_play">
                    <div class="play_info">
                        <img class="info_img1" src="@/assets/images/createaudio/audio_background.png" alt="">
                        <img class="info_img2" src="@/assets/images/createaudio/audio_play.png" alt="">
                        <el-image class="info_img3" :style="[imgStyle]" :src="info.avatar" fit="cover"></el-image>
                    </div>
                    <div class="audio">
                        <audio-player :ref="'play' + 0" :index='0' :fileurl='info.production_file' @audio="audioPlayBtn"></audio-player>
                    </div>
                </div>
            </div>
        </div>
        <div class="con-bottom section-wrap mt30 p20 fixed-r">
            <el-button size="small" type="primary" plain @click="backEdit">返回编辑</el-button>
            <el-button class="btnBgColor_blue" size="small" type="primary"
                @click="downloadFile(info.production_file, info.name)">下载</el-button>
        </div>
    </div>
</template>
<script>
import audioPlayer from '@/components/audioPlayer/index.vue'
import EditName from '../editCreationName/index.vue'

export default {
    components: { audioPlayer, EditName },
    props: {
        info: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            title: '音频详情',
            type: null,
        }
    },
    computed: {
        imgStyle(){
            if(this.type=='play'){
                return {
                    animationPlayState: 'running'
                }
            }else if(this.type=='pause'){
                return {
                    animationPlayState: 'paused'
                }
            }else{
                return {
                    animationPlayState: 'paused'
                }
            }
        }
    },
    methods: {
        // 播放
        audioPlayBtn(type){
            this.type = type
        },
        // 修改名称
        nameChange(val) {
            this.$creationApi.editAudioName({ name: val.name }, val.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$emit('refreshBtn')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 复制
        copyUrl(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 下载
        downloadFile(url, name) {
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const a = document.createElement("a");
                    const objectUrl = window.URL.createObjectURL(blob);
                    a.download = name;
                    a.href = objectUrl;
                    a.click();
                    window.URL.revokeObjectURL(objectUrl);
                    a.remove();
                })
        },
        // 返回编辑
        backEdit(){
            this.$store.dispatch('tabsArr/delTabList')
            this.$router.push({
                path: '/createaudio/index/' + this.info.id,
                query: {
                    name: this.info.name
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import './index.scss';

.detail {
    width: 100%;
    height: 100%;
    .con-top{
        height: calc(100% - 110px);
    }
    .audio_container_play{
        position: relative;
        width: 486px;
        height: 343px;
        margin: auto;
        overflow: hidden;
        .audio {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 486px;
            // height: 40px;
        }
        .play_info{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            .info_img1{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
            }
            .info_img2{
                position: absolute;
                top: 10px;
                left: 120px;
                z-index: 2;
                display: block;
                width: 270px;
                height: 270px;
            }
            .info_img3{
                position: absolute;
                top: 116px;
                left: 194px;
                z-index: 3;
                display: block;
                width: 90px;
                height: 90px;
                animation: rotate 10s linear infinite;
                -webkit-animation: rotate 10s linear infinite;
            }
        }
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>